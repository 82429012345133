@font-face {
  font-family: "Filson";
  src: local("Filson"),
    url(./fonts/Filson/FilsonProRegular.otf) format("opentype");
}

td.mat-cell {
  border-bottom-style: none;
}

th.mat-header-cell {
  border-bottom-style: none;
}

.App {
  width: "100vw";
}

html {
  overflow-x: hidden;
}

.header-box {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header-box:first-child > * {
  margin-right: auto;
}

.header-box:last-child > * {
  margin-left: auto;
}
